/* global tw */
import React from 'react';
import styled from 'react-emotion';
import { Parallax, ParallaxLayer } from 'react-spring';
import 'typeface-cantata-one';
import 'typeface-open-sans';
import SEO from '../components/SEO';
import SVG from '../components/SVG';
import ProjectCard from '../components/ProjectCard';
import { rotate, UpDown, UpDownWide, waveAnimation } from '../styles/animations';
import { hidden } from '../styles/utils';
import { colors } from '../../tailwind';
import triangle from '../images/triangle.svg';
import tempgrade from '../images/logo-no-pad.svg';
import '../styles/global';

const Divider = styled(ParallaxLayer)`
  ${tw('absolute w-full h-full')};
  background: ${props => props.bg};
  svg {
    fill: ${props => props.fill};
  }
  clip-path: ${props => props.clipPath};
`;

const Content = styled(ParallaxLayer)`
  ${tw('p-6 md:p-12 lg:p-24 justify-center items-center flex z-50')};
`;

const Hero = styled.div`
  ${tw('text-center w-full xl:w-2/3')};
`;

const Inner = styled.div`
  ${tw('w-full xxl:w-2/3 text-center lg:text-left')};
  @media (max-width: 400px) {
    width: auto;
  }
`;

const BigTitle = styled.h1`
  ${tw('text-3xl lg:text-4xl font-sans mb-6 tracking-wide')};
  color: #2e65c1;
`;

const Title = styled.h1`
  ${tw('text-2xl lg:text-3xl font-sans mb-8 tracking-wide relative inline-block')};
  color: #2e65c1;
  &:before {
    content: '';
    width: 40px;
    height: 40px;
    background: url(${triangle});
    position: absolute;
    background-size: 40px;
    animation: ${rotate} 4s linear infinite;
    left: -60px;
    top: 5px;
  }
`;

const Subtitle = styled.p`
  ${tw('text-2xl lg:text-4xl font-sans mt-8')};
  color: #2e65c1;
`;

const ProjectsWrapper = styled.div`
  ${tw('flex flex-wrap justify-between mt-8')};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

const WaveWrapper = styled.div`
  ${tw('absolute pin-b w-full')};
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

const InnerWave = styled.div`
  ${tw('relative h-full')};
  svg {
    width: 100%;
    height: 40vh;
  }
`;

const AboutHero = styled.div`
  ${tw('font-sans flex flex-col lg:flex-row items-center mt-8')};
`;

const Avatar = styled.img`
  ${tw('w-full h-full')};
`;

const AboutSub = styled.span`
  ${tw('font-sans pt-12 lg:pt-0 lg:pl-12 sm:text-base lg:text-xl xl:text-2xl')};
  color: #29abe2;
`;

const AboutDesc = styled.p`
  ${tw('text-grey font-sans pt-6 md:pt-12 max-w-lg sm:text-base lg:text-xl xl:text-2xl')};
  margin: 0 auto;
`;

const ContactText = styled.p`
  ${tw('font-sans sm:text-base lg:text-xl xl:text-2xl')};
  color: #9babb4;
  a {
    color: #e07628;
    text-decoration: none;
  }
`;

const Footer = styled.footer`
  ${tw('text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg')};
  a {
    color: #e07628;
    text-decoration: none;
  }
`;

const Index = () => (
  <React.Fragment>
    <SEO />
    <Parallax pages={5}>
      <Content speed={0.4} offset={0}>
        <Hero>
          <Avatar src={tempgrade}></Avatar>
          <BigTitle>Are you a non-tech founder?</BigTitle>
          <Subtitle>Let us build your product.</Subtitle>
        </Hero>
      </Content>
      <Content speed={0.4} offset={1.2} factor={2}>
        <Inner>
          <Title>Services</Title>
          <ProjectsWrapper>
            <ProjectCard
              title="Web apps"
              bg="linear-gradient(to right, #209faa 0%, #209faa 100%)"
            >
            We build powerful and scalable web apps using agile development processes and leveraging the latest web technologies.
            </ProjectCard>
            <ProjectCard
              title="iOS/Android apps"
              bg="linear-gradient(to right, #662D8C 0%,#662D8C 100%)"
            >
              Our mobile apps are featured in the app store and win in the marketplace. You will start getting your first mobile users from day 1.
            </ProjectCard>
            <ProjectCard
              title="Product management"
              bg="linear-gradient(to right, #046380 0%,#046380 100%)"
            >
              We manage your product across its complete life cycle, either it's an MVP or a market-ready product. 'Ongoing management' is the key to our success.
            </ProjectCard>
            <ProjectCard
              title="Copywriting/Localisation" 
              bg="linear-gradient(to right, #2e65c1 0%,#2e65c1 100%)"
            >
              We write your content and we localize it in the languages you need. No need for you to search for the best copywriters and translators; we already have them.
            </ProjectCard>
          </ProjectsWrapper>
        </Inner>
      </Content>
      <Content speed={0.4} offset={3}>
        <Inner>
          <Title>Why us</Title>
          <AboutHero>
            <AboutSub>
              We are a Athens-based digital studio of interdisciplinary professionals with tons of experience. We use cutting-edge technology to deliver both MVPs and market-ready products. All products go through multiple iterations, until you get what you expected. You get a dedicated Engagement Manager, end-to-end implementation, and ongoing management. Start getting users immediately!
            </AboutSub>
          </AboutHero>
          <AboutDesc>
              We only work under NDA. We white-label the product. The codebase belongs to you. Data privacy and confidentiality are ensured. The only owner is YOU!
          </AboutDesc>
        </Inner>
      </Content>
      <Divider speed={0.1} offset={1} factor={2}>
        <UpDown>
          <SVG icon="box" width={6} fill={colors['grey-darkest']} left="85%" top="75%" />
          <SVG icon="upDown" width={8} fill={colors.teal} left="70%" top="20%" />
          <SVG icon="triangle" width={8} stroke={colors.orange} left="25%" top="5%" />
          <SVG icon="circle" className={hidden} width={24} fill={colors['grey-darkest']} left="17%" top="60%" />
        </UpDown>
        <UpDownWide>
          <SVG icon="arrowUp" className={hidden} width={16} fill={colors.green} left="20%" top="90%" />
          <SVG icon="triangle" width={12} stroke={colors['grey-darkest']} left="90%" top="30%" />
          <SVG icon="circle" width={16} fill={colors.yellow} left="70%" top="90%" />
          <SVG icon="triangle" className={hidden} width={16} stroke={colors.teal} left="18%" top="75%" />
          <SVG icon="circle" width={6} fill={colors['grey-darkest']} left="75%" top="10%" />
          <SVG icon="upDown" className={hidden} width={8} fill={colors.green} left="45%" top="10%" />
        </UpDownWide>
        <SVG icon="circle" width={6} fill={colors['grey-darkest']} left="4%" top="20%" />
        <SVG icon="circle" width={12} fill={colors.pink} left="80%" top="60%" />
        <SVG icon="box" width={6} fill={colors.orange} left="10%" top="10%" />
        <SVG icon="box" width={12} fill={colors.yellow} left="29%" top="26%" />
        <SVG icon="hexa" width={16} stroke={colors.red} left="75%" top="30%" />
        <SVG icon="hexa" width={8} stroke={colors.yellow} left="80%" top="70%" />
      </Divider>
      <Divider fill="#2e65c1" speed={0.2} offset={4}>
        <WaveWrapper>
          <InnerWave>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 338.05" preserveAspectRatio="none">
              <path className={waveAnimation}>
                <animate
                  attributeName="d"
                  values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                  repeatCount="indefinite"
                  dur="30s"
                />
              </path>
            </svg>
          </InnerWave>
        </WaveWrapper>
      </Divider>
      <Content speed={0.4} offset={4}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
          Email us at <a href="mailto:info@qwerty-consultants.eu">info@qwerty-consultants.eu</a> some details about your project and we'll get back to you in a few hours, no matter what your time zone is.
          </ContactText>
        </Inner>
        <Footer>&copy; 2022 ~ All rights reserved</Footer>
      </Content>
      <Divider speed={0.1} offset={4}>
        <UpDown>
          <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="70%" top="20%" />
          <SVG icon="triangle" width={8} stroke={colors['grey-darkest']} left="25%" top="5%" />
        </UpDown>
        <UpDownWide>
          <SVG icon="triangle" width={12} stroke={colors['grey-darkest']} left="95%" top="50%" />
          <SVG icon="circle" width={6} fill={colors['grey-darkest']} left="85%" top="15%" />
          <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
        </UpDownWide>
      </Divider>
    </Parallax>
  </React.Fragment>
);

export default Index;
