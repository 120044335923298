const tailwind = require('../tailwind');

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Qwerty Consultants - Athens-based MVP studio', // Navigation and Site Title
  siteTitleAlt: 'TempGrade', // Alternative Site title for SEO
  siteUrl: 'https://qwerty-consultants.eu', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logos/logo.svg', // Used for SEO and manifest
  siteDescription: 'We build MVPs and market-ready products',
  ogLanguage: 'en_US', // Facebook Language

  // Manifest and Progress color
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue,
};
